import classes from './InfoCard.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import Typography from '@lobox/uikit/Typography';
import type { TypographyProps } from '@lobox/uikit/Typography';
import useCssVariables from '@shared/hooks/useCssVariables';
import { TOP_OF_LIST_INFO_CARD } from '@shared/constants/enums';

interface Props {
  title?: string;
  subTitle?: React.ReactNode;
  icon?: string;
  className?: any;
  direction?: 'column' | 'row';
  color?: 'primaryText' | 'success' | 'warning' | 'brand';
  value?: any;
  border?: boolean;
  disabledHover?: boolean;
  iconSize?: number;
  wrapperClassName?: string;
  valueProps?: Omit<TypographyProps, 'children'>;
}

const backgroundList = {
  success: 'success_5',
  warning: 'warning_5',
  brand: 'brand_5',
  primaryText: 'backgroundIconSecondary',
};
const InfoCard: React.FC<Props> = ({
  title,
  subTitle,
  icon,
  className,
  color = 'primaryText',
  value,
  border = false,
  direction = 'row',
  disabledHover,
  iconSize = 16,
  wrapperClassName,
  valueProps = {},
}) => {
  const styles = useCssVariables({
    scope: classes.InfoCardWrap,
    variables: {
      direction,
    },
  });
  return (
    <Flex
      className={cnj(border && classes.InfoCardRoot, className)}
      data-name={TOP_OF_LIST_INFO_CARD}
    >
      {styles}
      <Flex
        className={cnj(
          classes.InfoCardWrap,
          disabledHover && classes.InfoCardWrapNoHover,
          wrapperClassName
        )}
      >
        {icon && (
          <Flex
            className={cnj(
              classes.iconWrap,
              classes[`background-${backgroundList[color]}`]
            )}
          >
            <Icon size={iconSize} name={icon} color={color} type="far" />
          </Flex>
        )}
        {(title || subTitle) && (
          <Flex className={classes.textWrapper}>
            <Typography
              color="colorIconForth2"
              textAlign="left"
              size={13}
              font="500"
              height={15}
            >
              {title}
            </Typography>
            {typeof value === 'string' || !value ? (
              <OverflowTip
                size={15}
                color={value ? 'thirdText' : 'colorIconForth2'}
                mt={5}
                className={cnj(classes.subtitle, classes.truncate)}
                height={21}
                {...valueProps}
              >
                {value || subTitle}
              </OverflowTip>
            ) : (
              value
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default InfoCard;
